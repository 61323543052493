import React from "react";
import "../styles/components/press-excerpt.scss";
// import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { format, parseISO } from "date-fns";
import arrowIcon from "../img/landing/icon-arrow-orange.png";
import arrowIconWebp from "../img/landing/icon-arrow-orange.webp";
import Picture from "./Picture";

export default function PressExcerpt({ index, post }) {
  const myLink = `https://blog.ampthink.com/?p=${post.id}`;

  return (
    <div
      data-aos={index ? "fade-up" : ""}
      data-aos-mirror="true"
      data-asos-duration="300"
      className="press-excerpt"
    >
      <div className="press-excerpt-date">
        Posted {format(parseISO(post.date), "MM.dd.yyyy")}
      </div>

      <div className="press-excerpt-title">{parse(post.title.rendered)}</div>

      <div className="press-excerpt-preview">
        {parse(post.excerpt.rendered)}
      </div>

      <a
        href={myLink}
        rel="noreferrer"
        target="_blank"
        className="press-excerpt-read-more"
      >
        <div className="press-excerpt-read-more-text">Read more</div>

        <div className="press-excerpt-read-more-arrow">
          <Picture img={arrowIcon} webp={arrowIconWebp} alt="read more" />
        </div>
      </a>
    </div>
  );
}
