import React from 'react';
import '../../styles/containers/contact/offices.scss';
import dallasOffice from '../../img/contact/dallas.png';
import mnOffice from '../../img/contact/minneapolis.png';
import torontoOffice from '../../img/contact/toronto.png';
import OfficeInfo from './OfficeInfo';
import OfficeImage from './OfficeImage';
import WidthLimiter from '../../components/WidthLimiter';

export default function Offices() {
  return (
    <WidthLimiter>
      <div className='contact-offices'>
        <div className='contact-offices-heading'>
          Offices
        </div>

        <div className='contact-offices-row'>
          <div className='contact-offices-dallas'>
            <OfficeImage image={ dallasOffice } />

            <OfficeInfo
              title='DALLAS HQ'>
              3827 Dividend Dr.<br/>
              Garland, TX 75042
            </OfficeInfo>
          </div>

          <div className='contact-offices-halfs'>
            <div className='contact-offices-half'>
              <OfficeImage image={ mnOffice } />

              <OfficeInfo
                title='MINNEAPOLIS'>
                3711 26th Ave. South<br/>
                Minneapolis, MN 55406
              </OfficeInfo>
            </div>

            <div className='contact-offices-half'>
              <OfficeImage image={ torontoOffice } />

              <OfficeInfo
                title='TORONTO'>
                835 Westney Rd S Unit 12,<br/>
                Ajax, ON L1S 3M4<br/>
                Canada 
              </OfficeInfo>
            </div>
          </div>
        </div>
      </div>
    </WidthLimiter>
  )
}
